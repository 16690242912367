@use 'sass:math';
@import '../variables.scss';

.lau-ComponentMargin {
  &--top {
    margin-top: 0.5rem;
  }

  &--bottom {
    margin-bottom: $xpo-contentSpacing * 5;
  }
}

.lau-ComponentPadding {
  padding: 0 math.div($xpo-contentSpacing, 2) math.div($xpo-contentSpacing, 2);
}
