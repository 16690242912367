@use 'sass:math';

/* imports and utilities like animations and mixins should be defined here */
@import '@xpo-ltl/ngx-ltl-core/styles/_variables';

// Layout styles
@import '../assets/styles/layout/grid-customization';
@import '../assets/styles/layout/spacing.scss';

// Forms
@import '../assets/styles/custom/form.scss';

// Utils
@import '../assets/styles/custom/utils.scss';

// Ag-grid
@import '../assets/styles/ag-grid/ag-grid-theme.scss';

@import '../assets//styles/mat-custom.scss';

// Custom colors
$xpo-contract-method: #616161;
$xpo-contract-method-get: #b3e5fc;
$xpo-contract-method-put: #ffccbc;
$xpo-contract-method-post: #c8e6c9;
$xpo-contract-method-delete: #ffcdd2;
$xpo-contract-method-disabled: #e2e2e2;

.app {
  &[class*='--hasActionBar'] {
    margin-bottom: $lau-ActionBar-height + 1;

    .app-ActionBar {
      margin-bottom: -$lau-ActionBar-height;
    }
  }
}

body {
  overflow: hidden;
}

mat-tooltip-component {
  .option-tooltip {
    max-width: unset;
  }

  .mdc-tooltip__surface {
    background-color: #212121;
    border-radius: 0;
    color: #ffffff;
    padding: 10px;
  }
}

.mat-mdc-icon-button[disabled] {
  opacity: 0.38 !important;
}

xpo-card-footer {
  .xpo-Link {
    text-decoration: none !important;
  }
}

contract-view {
  .mat-tab-body-wrapper {
    padding: 1rem;
  }

  .mat-expansion-indicator {
    margin-right: 1rem;
  }
}

contract-body {
  .xpo-StatusIndicator-label mat-icon.mat-icon {
    margin-left: 0;
    padding: 0;
  }

  .xpo-StatusIndicator-label-text {
    font-size: $xpo-header-fontSize--large;
    font-weight: bold;
  }
}

.mdc-text-field--disabled {
  opacity: 0.38 !important;
}

contract-header {
  .xpo-StatusIndicator-tag {
    font-size: 0.7rem;
  }
}

.ag-center-cols-viewport {
  width: calc(100% + 18px) !important;
}

.aem-grid {
  margin-top: 20px;

  .ag-sort-order {
    display: none;
  }

  .aem-mat-icon {
    margin-left: 12px;
    margin-top: 23px;
    position: absolute;
  }

  & input {
    border: 1px solid lightgray;
    font-size: 15px;
    margin-bottom: 20px;
    margin-left: 10px;
    margin-top: 20px;
    padding: 5px 5px 5px 30px;
    width: 99%;
  }

  & .rules-grid {
    border-bottom: 1px solid lightgray;
  }

  & p {
    text-align: center;
  }
}

aem-button-renderer {
  button.xpo-SmallButton.mat-stroked-button.mat-button-base {
    margin: 3px;
    width: 70%;
  }
}

.ag-theme-material .ag-header-row:first-child .ag-header-cell,
.ag-theme-material .ag-header-row:first-child .ag-header-group-cell {
  border-top-width: 1px !important;
}

.aem-container {
  margin-top: 0.5rem;

  xpo-card {
    padding: 0 8px 11px;

    xpo-card-title {
      b.code-description,
      i.material-icons {
        font-size: 1.05rem;
        margin-left: -8px;
      }
    }

    xpo-card-header {
      align-items: center;
      display: flex;
      flex: 0 0 auto;
      min-height: 35px;
      padding: 8px;

      .aem-title {
        font-size: 1.428rem;
        font-weight: 500;
        letter-spacing: 0.38px;
        line-height: 1.286;
        margin: 8px 0;
      }
    }

    xpo-card-content {
      .aem-content {
        display: block;
        overflow: auto;
        padding: 10px;
      }

      .aem-action {
        button {
          margin-right: 11px;
        }
      }
    }
  }
}

.aem-dialog {
  h1.mat-dialog-title {
    padding-top: 30px !important;
  }

  div.mat-dialog-content {
    display: flex;
    flex-direction: column;
    flex-wrap: nowrap;
    overflow-x: hidden !important;
    padding: 8px 24px 16px !important;
  }
}

div.lau-AttributesDialog.mat-dialog-content {
  overflow-x: hidden !important;
}

.mat-select-panel {
  left: 7px;
  min-width: calc(100% + 18px) !important;
  position: relative;
}

@mixin xpo-scroll {
  &::-webkit-scrollbar-track {
    background-color: $xpo-grey--100;
    -webkit-box-shadow: inset 0 0 6px rgba(251, 251, 251, 0.3);
  }

  &::-webkit-scrollbar {
    background-color: $xpo-grey--100;
    width: 0.714rem;
  }

  &::-webkit-scrollbar-thumb {
    background-color: $xpo-grey--200;
    border: 1px solid $xpo-grey--250;
  }
}

.swagger {
  flex: 1;
  overflow-y: auto;
  @include xpo-scroll;
}

.swagger-ui {
  .wrapper {
    margin: 0;
  }

  .opblock-body pre.microlight {
    background-color: black !important;
    color: white !important;
    @include xpo-scroll;

    code,
    code.language-json {
      background-color: black !important;
    }
  }
}

.ag-grid-custom-warning {
  div.ag-column-hover {
    background-color: transparent;
  }
}

.app-api-store {
  .api-store-content {
    .api-store-content-filter {
      .mat-form-field-flex {
        border-radius: 8px;
        box-shadow:
          0 1px 2px 0 rgb(60 64 67 / 30%),
          0 0 3px 1px rgb(60 64 67 / 15%);
      }
    }
  }
}

.mdc-menu-surface.mat-mdc-select-panel {
  background-color: white;
  box-shadow:
    0 5px 5px -3px #0003,
    0 8px 10px 1px #00000024,
    0 3px 14px 2px #0000001f;
}

mat-form-field.mat-mdc-form-field {
  --mat-mdc-form-field-floating-label-scale: 1;
}

label.mdc-floating-label--float-above {
  transform: translateY(-34.75px) scale(1) !important;
}

.xpo-TabDrawer-contentWrapper.mat-drawer-content {
  overflow: hidden !important;
}

.mdc-list-item:hover {
  background-color: whitesmoke;
}

.xpo-Header {
  background-color: white;
}
