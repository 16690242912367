@import '../variables.scss';

.lau-ag-theme {
  flex-grow: 1;
  overflow: hidden;

  // TODO: Remove this once the 'display: inline-flex;' has been removed from xpo-ag-grid,
  // that is affecting the overflow hidden in '.ag-cell'
  &.xpo-AgGrid.ag-theme-material {
    .ag-cell {
      display: inline-block;
      // This references the rowHeight defined in ServiceConstants,
      // it should be added in xpo-ag-grid
      line-height: 38px;
      padding-left: 12px;
      padding-right: 12px;
    }

    .ag-header {
      border-top: 1px solid #D8D8D8;
    }
  }

  .ag-cell-inline-editing {
    .ag-input-wrapper {
      input[type='text'] {
        height: 100% !important;
        width: 100%;
      }
    }

    select.ag-cell-edit-input {
      border: none;
      border-radius: 0;
    }
  }

  .lau-EditableCell {
    background-color: $xpo-yellow--100;
  }

  .lau-CellText-ellipsis {
    overflow: hidden;
    text-overflow: ellipsis;
    white-space: nowrap;
  }
}
