@use 'sass:math';

.xpo-Card-title {
  h1.lau-PageTitle {
    color: $xpo-grey--900 !important;
  }
}

.lau-GridSearch-input {
  &.mat-form-field-appearance-legacy {
    .mat-form-field-wrapper {
      border-left: 0;
      border-right: 0;
      border-top: 0;
      font-size: $xpo-fontSize--medium;
      line-height: $xpo-lineHeight--base;
      margin: 0;
    }
  }
}

.lau-GridSearch-select {
  &.mat-form-field-appearance-legacy {
    .mat-form-field-wrapper {
      margin: math.div($xpo-contentSpacing, 4) 0;
    }
  }
}

.lau-InfoText {
  font-size: $xpo-fontSize--small;
  margin-left: $xpo-contentSpacing;

  .mat-icon {
    color: $xpo-grey--400;
    font-size: $xpo-fontSize--medium;
    vertical-align: sub;

    &.lau-EditableIndicator {
      color: $xpo-yellow--100;
    }
  }
}
